import * as React from "react"
import { Col, Container, Row } from "reactstrap"
import { Link } from "gatsby"

import Seo from "../components/seo"

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <Container className="error h-100" tag="section" fluid>
      <Row className="align-items-center h-100 justify-content-center">
        <Col xs={12} md={5}>
          <h1 className="error__title text-center text-uppercase">
            Página no encontrada
          </h1>
          <article className="mt-4 text-center">
            <p className="error__paragraph mb-4 mt-2">
              Acabas de llegar a una ruta que no existe ...
              <br /> ¡la tristeza!
            </p>
            <Link to="/" className="error__link text-uppercase">
              Ir al Inicio
            </Link>
          </article>
        </Col>
      </Row>
    </Container>
  </>
)

export default NotFoundPage
